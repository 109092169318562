import $ from 'jquery';

$(document).ready(function () {
  step11();
});

function step11() {
  let excessPolicyForm = $('#excess-policy-form');

  $('#step-11-submit').on('click', function (el) {
    el.preventDefault();
    let s11Nextlink = $(this).attr('href');
    let file1 = $('#current_excess_policy')[0].files[0];
    let checkbox = $('#exclude_occupational_confirm').is(':checked');
    var formData = new FormData();

    formData.append('action', 'excess_policy');
    formData.append('excess_policy_file', file1);
    formData.append('excess_policy_checkbox', checkbox);
    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.text())
      .then((data) => {
        window.location.href = s11Nextlink;
      })
      .catch((err) => console.log(err));
  });

  $('input[name="current_excess_policy"]').on('change', function () {
    var fileName = $(this).val().split('\\').pop();
    $(this)
      .parents('.form-upload-button-wrap')
      .append("<span class='file_names'>" + fileName + '</span>');
  });

  $('.info-button').on('click', function () {
    let parent = $(this).parent('.excess-policy-form__item');
    let item = parent.find('.info-button');
    item.addClass('info-show');
    let text = parent.next();
    text.slideDown();
  });

  $('.i-text__close').on('click', function () {
    let parent = $(this).parent('.i-text');
    parent.slideUp();
  });

  $('.current_excess_policy-button').on('click', function () {
    $('input[name="current_excess_policy"]').click();
  });

  $('.step-11-next').on('click', function (el) {
    el.preventDefault();
    let s11Nextlink = $(this).attr('href');
    let file1 = $('#current_excess_policy')[0].files[0];
    let checkbox = $('#exclude_occupational_confirm').is(':checked');
    var formData = new FormData();

    formData.append('action', 'excess_policy');
    formData.append('excess_policy_file', file1);
    formData.append('excess_policy_checkbox', checkbox);
    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.text())
      .then((data) => {
        window.location.href = s11Nextlink;
      })
      .catch((err) => console.log(err));
  });

  //comment submit
  var rejectFormButton = $('#step-11-reject-form');
  var commentForm = $('#comment-11-step-form');

  $('#step-11-reject-form').on('submit', function (el) {
    let comment = commentForm.find('textarea').val();
    let hiddenComment = rejectFormButton.find('.hidden-comment').val(comment);
  });
}
