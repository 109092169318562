import $ from 'jquery';
import { find } from 'ramda';

$(document).ready(function () {
  step4();
});

function step4() {
  $('.no-parent').on('click', function () {
    $('.no-additional-companies').submit();
  });
  let additionalCompaniesForm = $('#additional-companies-form');
  $('#step-4-submit').on('click', function (el) {
    el.preventDefault();

    let s4link = $(this).attr('href');

    var formDataArray = [];
    var existingFormDataArray = [];
    var previousExistingFormDataArray = [];

    $('#additional-companies-form .additional-company-edit-wrap').each(
      function () {
        var formData = {};

        formData.company_name = $(this)
          .find('input[name="company_name"]')
          .val();
        formData.address = $(this).find('input[name="address"]').val();
        formData.address_suite = $(this)
          .find('input[name="address_suite"]')
          .val();
        formData.city = $(this).find('input[name="city"]').val();
        formData.state = $(this).find('input[name="state"]').val();
        formData.zip = $(this).find('input[name="zip"]').val();
        formData.company_type = $(this)
          .find('select[name="company_type"]')
          .val();

        formDataArray.push(formData);
      }
    );
    formDataArray = JSON.stringify(formDataArray);

    $('#additional-companies-form .existing-wrap').each(function () {
      var existingFormData = {};

      existingFormData.row_count = $(this)
        .find('input[name="row_count"]')
        .val();
      existingFormData.company_name = $(this)
        .find('input[name="parent_company_name"]')
        .val();
      existingFormData.address = $(this).find('input[name="address"]').val();
      existingFormData.address_suite = $(this)
        .find('input[name="address_suite"]')
        .val();
      existingFormData.city = $(this).find('input[name="city"]').val();
      existingFormData.state = $(this).find('input[name="state"]').val();
      existingFormData.zip = $(this).find('input[name="zip"]').val();
      existingFormData.company_type = $(this)
        .find('select[name="company_type"]')
        .val();

      existingFormDataArray.push(existingFormData);
    });

    existingFormDataArray = JSON.stringify(existingFormDataArray);

    $('#additional-companies-form .previous-existing-wrap').each(function () {
      var previousExistingFormData = {};

      previousExistingFormData.row_count = $(this)
        .find('input[name="row_count"]')
        .val();
      previousExistingFormData.company_name = $(this)
        .find('input[name="parent_company_name"]')
        .val();
      previousExistingFormData.address = $(this)
        .find('input[name="address"]')
        .val();
      previousExistingFormData.address_suite = $(this)
        .find('input[name="address_suite"]')
        .val();
      previousExistingFormData.city = $(this).find('input[name="city"]').val();
      previousExistingFormData.state = $(this)
        .find('input[name="state"]')
        .val();
      previousExistingFormData.zip = $(this).find('input[name="zip"]').val();
      previousExistingFormData.company_type = $(this)
        .find('select[name="company_type"]')
        .val();

      previousExistingFormDataArray.push(previousExistingFormData);
    });

    previousExistingFormDataArray = JSON.stringify(
      previousExistingFormDataArray
    );

    var formData = new FormData();
    formData.append('action', 'add_to_additional_companies_repeater');
    formData.append('additionalCompaniesRepeaterData', formDataArray);
    formData.append('existingRepeaterData', existingFormDataArray);
    formData.append(
      'previousExistingFormDataArray',
      previousExistingFormDataArray
    );
    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    }) // wrapped
      .then((res) => res.text())
      .then((data) => {
        window.location.href = s4link;
      })
      .catch((err) => console.log(err));
  });
  $('.step-4 .selection-buttons .item-yes').on('click', function () {
    $('.additional-companies').show();
    $('.step-4 .info-text').hide();
    // $('.step-4 .selection-buttons').hide();
    // $('.step-4 .info-text-edit-wrap').show();
    $('.step-4-next').show();
  });

  //comment submit
  var rejectFormButton = $('#step-4-reject-form');
  var commentForm = $('#comment-4-step-form');

  $('#step-4-reject-form').on('submit', function (el) {
    let comment = commentForm.find('textarea').val();
    let hiddenComment = rejectFormButton.find('.hidden-comment').val(comment);
  });

  var $rowTemplate = $('.step-4 #row-template .new-item-wrap');

  $('.step-4 .add-row').on('click', function () {
    var $newRow = $rowTemplate.clone().removeAttr('id');
    additionalCompaniesForm.append($newRow);
  });

  $('.step-4-next').on('click', function (el) {
    el.preventDefault();

    let s4link = $(this).attr('href');

    var formDataArray = [];
    var existingFormDataArray = [];
    var previousExistingFormDataArray = [];

    $('#additional-companies-form .additional-company-edit-wrap').each(
      function () {
        var formData = {};

        formData.company_name = $(this)
          .find('input[name="company_name"]')
          .val();
        formData.address = $(this).find('input[name="address"]').val();
        formData.address_suite = $(this)
          .find('input[name="address_suite"]')
          .val();
        formData.city = $(this).find('input[name="city"]').val();
        formData.state = $(this).find('input[name="state"]').val();
        formData.zip = $(this).find('input[name="zip"]').val();
        formData.company_type = $(this)
          .find('select[name="company_type"]')
          .val();

        formDataArray.push(formData);
      }
    );
    formDataArray = JSON.stringify(formDataArray);

    $('#additional-companies-form .existing-wrap').each(function () {
      var existingFormData = {};

      existingFormData.row_count = $(this)
        .find('input[name="row_count"]')
        .val();
      existingFormData.company_name = $(this)
        .find('input[name="parent_company_name"]')
        .val();
      existingFormData.address = $(this).find('input[name="address"]').val();
      existingFormData.address_suite = $(this)
        .find('input[name="address_suite"]')
        .val();
      existingFormData.city = $(this).find('input[name="city"]').val();
      existingFormData.state = $(this).find('input[name="state"]').val();
      existingFormData.zip = $(this).find('input[name="zip"]').val();
      existingFormData.company_type = $(this)
        .find('select[name="company_type"]')
        .val();

      existingFormDataArray.push(existingFormData);
    });

    existingFormDataArray = JSON.stringify(existingFormDataArray);

    $('#additional-companies-form .previous-existing-wrap').each(function () {
      var previousExistingFormData = {};

      previousExistingFormData.row_count = $(this)
        .find('input[name="row_count"]')
        .val();
      previousExistingFormData.company_name = $(this)
        .find('input[name="parent_company_name"]')
        .val();
      previousExistingFormData.address = $(this)
        .find('input[name="address"]')
        .val();
      previousExistingFormData.address_suite = $(this)
        .find('input[name="address_suite"]')
        .val();
      previousExistingFormData.city = $(this).find('input[name="city"]').val();
      previousExistingFormData.state = $(this)
        .find('input[name="state"]')
        .val();
      previousExistingFormData.zip = $(this).find('input[name="zip"]').val();
      previousExistingFormData.company_type = $(this)
        .find('select[name="company_type"]')
        .val();

      previousExistingFormDataArray.push(previousExistingFormData);
    });

    previousExistingFormDataArray = JSON.stringify(
      previousExistingFormDataArray
    );

    var formData = new FormData();
    formData.append('action', 'add_to_additional_companies_repeater');
    formData.append('additionalCompaniesRepeaterData', formDataArray);
    formData.append('existingRepeaterData', existingFormDataArray);
    formData.append(
      'previousExistingFormDataArray',
      previousExistingFormDataArray
    );
    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    }) // wrapped
      .then((res) => res.text())
      .then((data) => {
        window.location.href = s4link;
      })
      .catch((err) => console.log(err));
  });

  $('.step-4 .del-button').on('click', function () {
    let parent = $(this).parent('.item-wrap');
    let row = parent.find('.row-count').val();

    row = JSON.stringify(row);
    var formData = new FormData();
    formData.append('action', 'delete_additional_repeater_row');
    formData.append('delData', row);

    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    }) // wrapped
      .then((res) => res.text())
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
    parent.remove();
  });
  $(document).on('click', '.step-4 .del-new-button', function () {
    let parent = $(this).parent('.new-item-wrap');
    parent.remove();
  });

  $('.info-button').on('click', function () {
    let parent = $(this).parent('.step-4 .info-text');
    let item = parent.find('.info-text');
    item.addClass('info-show');
    let text = parent.next();
    text.slideDown();
  });

  $('.i-text__close').on('click', function () {
    let parent = $(this).parent('.i-text');
    parent.slideUp();
  });
}
