import $ from 'jquery';

$(document).ready(function () {
  step7();
});

function step7() {
  let claimsDataForm = $('#claims-data-form');

  $('.info-button').on('click', function () {
    let parent = $(this).parent('.claims-data-form__item');
    let item = parent.find('.info-button');
    item.addClass('info-show');
    let text = parent.next();
    text.slideDown();
  });

  $('.i-text__close').on('click', function () {
    let parent = $(this).parent('.i-text');
    parent.slideUp();
  });

  $('#outstanding_reserves').on('change', function () {
    let previosSumm = parseInt($('#prev-year-summ').val());
    let outstanding_reserves = $(this).val();
    let medical_payments = $('#medical_payments').val();
    let indemnityPayments = $('#indemnity_payments').val();
    let allSumm =
      parseInt(medical_payments) +
      parseInt(indemnityPayments) +
      parseInt(outstanding_reserves);

    let difference = Math.abs(previosSumm - allSumm);
    let percentageDifference =
      (difference / Math.max(previosSumm, allSumm)) * 100;
    let finalValue = percentageDifference.toFixed(1);

    let message =
      'Your percentage difference between the current year and last year is ' +
      finalValue +
      '%';

    let textAtea = $('#claims-data-form #message');
    if (finalValue >= 20) {
      textAtea.val(message);
    } else {
      textAtea.val('');
    }
  });

  //comment submit
  var rejectFormButton = $('#step-7-reject-form');
  var commentForm = $('#comment-7-step-form');

  $('#step-7-reject-form').on('submit', function (el) {
    let comment = commentForm.find('textarea').val();
    let hiddenComment = rejectFormButton.find('.hidden-comment').val(comment);
  });

  $('#step-7-submit').on('click', function (el) {
    el.preventDefault();
    let s7link = $(this).attr('href');

    let formArray = [];
    function objectifyForm(formArray) {
      //serialize data function
      var returnArray = {};
      for (var i = 0; i < formArray.length; i++) {
        returnArray[formArray[i]['name']] = formArray[i]['value'];
      }
      return returnArray;
    }

    let form = document.querySelector('#claims-data-form');
    let data = objectifyForm(form);
    formArray.push(data);
    formArray = JSON.stringify(formArray);
    console.log(formArray);
    var formData = new FormData();

    formData.append('action', 'claims_data');
    formData.append('claims_data_data', formArray);
    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.text())
      .then((data) => {
        window.location.href = s7link;
      })
      .catch((err) => console.log(err));
  });

  $('.step-7-next').on('click', function (el) {
    el.preventDefault();

    let s7link = $(this).attr('href');

    let formArray = [];
    function objectifyForm(formArray) {
      //serialize data function
      var returnArray = {};
      for (var i = 0; i < formArray.length; i++) {
        returnArray[formArray[i]['name']] = formArray[i]['value'];
      }
      return returnArray;
    }

    let form = document.querySelector('#claims-data-form');
    let data = objectifyForm(form);
    formArray.push(data);
    formArray = JSON.stringify(formArray);
    console.log(formArray);
    var formData = new FormData();

    formData.append('action', 'claims_data');
    formData.append('claims_data_data', formArray);
    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.text())
      .then((data) => {
        window.location.href = s7link;
      })
      .catch((err) => console.log(err));
  });
}
