import $ from 'jquery';

$(document).ready(function () {
  step12();
});

function step12() {
  let directorsOfficersForm = $('#directors-and-officers-form');
  $('#step-12-submit').on('click', function (el) {
    el.preventDefault();
    let s12link = $(this).attr('href');

    if (directorsOfficersForm.length) {
      directorsOfficersForm.submit();
      setTimeout(function () {
        window.location.href = s12link;
      }, 300);
    }
  });

  $('.selection-buttons span').on('click', function () {
    $('.directors-and-officers-info').hide();
    $('.directors-and-officers-form-wrap').show();
  });

  var $rowTemplate = $(
    '#row-template .directors-and-officers-form__item-wrap'
  ).clone();

  $('.step-12 .add-row').on('click', function () {
    var $newRow = $rowTemplate.clone().removeAttr('id');
    directorsOfficersForm.append($newRow);
    $('.new-directors-and-officers-del').show();
  });

  directorsOfficersForm.on('submit', function (e) {
    e.preventDefault();

    var formDatas = []; // Initialize an empty object
    var existingFormDataArray = [];
    var previosExistingFormDataArray = [];

    let container = document.getElementById('directors-and-officers-form');
    var textFields = container.querySelectorAll('.new-row');
    var existingTextFields = container.querySelectorAll('.existing-row');

    textFields.forEach(function (item) {
      let formItem = {};
      let fields = item.querySelectorAll('input[type="text"]');
      fields.forEach(function (field) {
        formItem[field.name] = field.value;
      });
      formDatas.push(formItem);
    });

    $('#directors-and-officers-form .existing-row').each(function () {
      var existingFormData = {};

      existingFormData.directors_row_count = $(this)
        .find('input[name="directors_row_count"]')
        .val();
      existingFormData.insured_name = $(this)
        .find('input[name="insured_name"]')
        .val();
      existingFormData.limits_of_coverage = $(this)
        .find('input[name="limits_of_coverage"]')
        .val();
      existingFormData.issuing_company = $(this)
        .find('input[name="issuing_company"]')
        .val();
      existingFormData.policy = $(this).find('input[name="policy"]').val();

      existingFormDataArray.push(existingFormData);
    });

    $('#directors-and-officers-form .previos-existing-row').each(function () {
      var previosExistingFormData = {};

      previosExistingFormData.directors_row_count = $(this)
        .find('input[name="previos_directors_row_count"]')
        .val();
      previosExistingFormData.insured_name = $(this)
        .find('input[name="insured_name"]')
        .val();
      previosExistingFormData.limits_of_coverage = $(this)
        .find('input[name="limits_of_coverage"]')
        .val();
      previosExistingFormData.issuing_company = $(this)
        .find('input[name="issuing_company"]')
        .val();
      previosExistingFormData.policy = $(this)
        .find('input[name="policy"]')
        .val();

      previosExistingFormDataArray.push(previosExistingFormData);
    });

    previosExistingFormDataArray = JSON.stringify(previosExistingFormDataArray);
    existingFormDataArray = JSON.stringify(existingFormDataArray);
    formDatas = JSON.stringify(formDatas);
    console.log(previosExistingFormDataArray);
    var formData = new FormData();
    formData.append('action', 'directors_officers_repeater');
    formData.append('repeaterDirectorsData', formDatas);
    formData.append('existingRepeaterDirectorsData', existingFormDataArray);
    formData.append(
      'previosExistingRepeaterDirectorsData',
      previosExistingFormDataArray
    );

    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    }) // wrapped
      .then((res) => res.text())
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  });

  //delete
  $('.del-button').on('click', function () {
    let parent = $(this).parent('.directors-and-officers-info__item');
    let row = parent.find('.row-count').val();

    row = JSON.stringify(row);
    var formData = new FormData();
    formData.append('action', 'delete_directors_repeater_row');
    formData.append('delDirectorsData', row);

    fetch(ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
    }) // wrapped
      .then((res) => res.text())
      .then((data) => console.log(data))
      .catch((err) => console.log(err));

    parent.remove();
  });
  $(document).on('click', '.step-12 .del-new-button', function () {
    let parent = $(this).parent('.directors-and-officers-info__item');
    parent.remove();
  });

  $('.step-12-next').on('click', function (el) {
    el.preventDefault();

    directorsOfficersForm.submit();
    let s12link = $(this).attr('href');

    setTimeout(function () {
      window.location.href = s12link;
    }, 300);
  });

  //comment submit
  var rejectFormButton = $('#step-12-reject-form');
  var commentForm = $('#comment-12-step-form');

  $('#step-12-reject-form').on('submit', function (el) {
    let comment = commentForm.find('textarea').val();
    let hiddenComment = rejectFormButton.find('.hidden-comment').val(comment);
  });

  var originalText = $('.step-12 .add-row-text').text();

  $('.step-12 .add-row-text').mouseenter(function () {
    $(this).text('Add new');
  });

  $('.step-12 .add-row-text').mouseleave(function () {
    $(this).text(originalText);
  });
}
