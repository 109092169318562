import $ from 'jquery';

$(document).ready(function () {
  acfFaqBlock();
});
function acfFaqBlock() {
  $('.js-acf-faq-block-block').each(function () {
    $('.faq-item').click(function () {
      $('.faq-item__answer', this).slideToggle();
      $('.faq-item__head', this).toggleClass('active-item');
    });
  });
}
