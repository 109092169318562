import $ from 'jquery';

$(document).ready(function () {
  step8();
});

function step8() {
  let reimbursableClaimsDataForm = $('#reimbursable-claims-form');
  $('#step-8-submit').on('click', function (el) {
    el.preventDefault();
    let s8link = $(this).attr('href');

    if (
      $('#reimbursable-claims-form [required]').filter(function () {
        return $(this).val() === '';
      }).length > 0
    ) {
      $('#reimbursable-claims-form [required]').css(
        'border',
        '#ff0766 solid 2px'
      );
    } else {
      let form = document.querySelector('#reimbursable-claims-form');
      let formArray = [];
      function objectifyForm(formArray) {
        //serialize data function
        var returnArray = {};
        for (var i = 0; i < formArray.length; i++) {
          returnArray[formArray[i]['name']] = formArray[i]['value'];
        }
        return returnArray;
      }

      let data = objectifyForm(form);
      formArray.push(data);
      formArray = JSON.stringify(formArray);
      let file1 = $('#all_open_self-insured_claims-button')[0].files[0];
      let file2 = $('#open_excess-button')[0].files[0];
      let allFiles = [file1, file2];
      var formData = new FormData();

      formData.append('action', 'custom_text');
      formData.append('reimbursable_claims', formArray);
      formData.append('reimbursable_claims_file1', file1);
      formData.append('reimbursable_claims_file2', file2);
      fetch(ajax_object.ajax_url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.text())
        .then((data) => {
          window.location.href = s8link;
        })
        .catch((err) => console.log(err));
    }
  });

  $('input[name="all_open_self-insured_claims"]').on('change', function () {
    var fileName = $(this).val().split('\\').pop();
    $(this)
      .parents('.form-upload-button-wrap')
      .append("<span class='file_names'>" + fileName + '</span>');
  });

  $('input[name="open_excess"]').on('change', function () {
    var fileName = $(this).val().split('\\').pop();
    $(this)
      .parents('.form-upload-button-wrap')
      .append("<span class='file_names'>" + fileName + '</span>');
  });

  $('.info-button').on('click', function () {
    let parent = $(this).parent('.reimbursable-claims-form__item');
    let item = parent.find('.info-button');
    item.addClass('info-show');
    let text = parent.next();
    text.slideDown();
    console.log(text);
  });

  $('.i-text__close').on('click', function () {
    let parent = $(this).parent('.i-text');
    parent.slideUp();
  });

  $('.open_excess-button').on('click', function () {
    $('input[name="open_excess"]').click();
  });

  $('.all_open_self-insured_claims-button').on('click', function () {
    $('input[name="all_open_self-insured_claims"]').click();
  });

  $('.contact-edit').on('click', function () {
    let parent = $(this).parent('.reimbursable-claims-form__item');
    let info = parent.find('.info-wrap');
    info.hide();
    let wrap = parent.find('.fields-wrap');
    wrap.css('display', 'flex');
    $(this).hide();
  });

  //comment submit
  var rejectFormButton = $('#step-8-reject-form');
  var commentForm = $('#comment-8-step-form');

  $('#step-8-reject-form').on('submit', function (el) {
    let comment = commentForm.find('textarea').val();
    let hiddenComment = rejectFormButton.find('.hidden-comment').val(comment);
  });

  $('.step-8-next').on('click', (el) => {
    el.preventDefault();

    if (
      $('#reimbursable-claims-form [required]').filter(function () {
        return $(this).val() === '';
      }).length > 0
    ) {
      $('#reimbursable-claims-form [required]').css(
        'border',
        '#ff0766 solid 2px'
      );
      $('html, body').animate({ scrollTop: 0 }, 'slow');
    } else {
      let form = document.querySelector('#reimbursable-claims-form');
      let formArray = [];
      function objectifyForm(formArray) {
        //serialize data function
        var returnArray = {};
        for (var i = 0; i < formArray.length; i++) {
          returnArray[formArray[i]['name']] = formArray[i]['value'];
        }
        return returnArray;
      }

      let data = objectifyForm(form);
      formArray.push(data);
      formArray = JSON.stringify(formArray);
      let file1 = $('#all_open_self-insured_claims-button')[0].files[0];
      let file2 = $('#open_excess-button')[0].files[0];
      let allFiles = [file1, file2];
      var formData = new FormData();

      formData.append('action', 'custom_text');
      formData.append('reimbursable_claims', formArray);
      formData.append('reimbursable_claims_file1', file1);
      formData.append('reimbursable_claims_file2', file2);
      fetch(ajax_object.ajax_url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.text())
        .then((data) => {
          window.location.href = $('.step-8-next').attr('href');
        })
        .catch((err) => console.log(err));
    }
  });
}
